.forgot-password-container {
  display: flex;
  overflow: hidden;
}

.email-section, .password-section {
  width: 100%;
  transition: transform 0.5s ease-in-out;
}

.email-sent .email-section {
  transform: translateX(-100%);
}

.email-sent .password-section {
  transform: translateX(0);
}
