/* @import 'node_modules/react-datepicker/src/stylesheets/datepicker.scss'; */

/* @import '../../../node_modules/react-datepicker/src/stylesheets/datepicker.scss'; */



.navhover:hover {
  background-color: red;
}

.inputborder:focus {
  border: 1px solid #333 !important;
}

.placeholderColor::placeholder {
  color: #cacaca;
}

.border-radius {
  border-radius: 200px !important;
}

.no-outline .MuiOutlinedInput-notchedOutline {
  border-color: transparent !important;
}

.css-1rcem0m-MuiAutocomplete-root
  .MuiOutlinedInput-root
  .MuiAutocomplete-input {
  padding: 3px 4px 1px 6px !important;
  font-size: 14px;
}





/* YourComponent.css */
.container {
  display: grid;
  grid-template-columns: repeat(
    auto-fill,
    minmax(350px, 1fr)
  ); /* Adjust minmax values */
  gap: 20px; /* Adjust the gap between items */
  padding-right: 100px; 
  padding-left: 100px; 
  margin-right: auto;
  margin-left: auto;
}

.item {
  /* background-color: #e0e0e0; */
  /* padding: 20px; */
  /* border-radius: 8px; */
  /* box-shadow: 0 2px 4px rgba(0, 0, 0, 0.1); */
}

@media (max-width: 768px) {
  .item {
    padding-right: 10px; 
    padding-left: 10px;  
    display: flex;
    justify-content: center;
    /* align-items: center;
    align-self: center;
    align-content: center; */
  }
}

/* Media queries for responsiveness */
@media (max-width: 768px) {
  .container {
    grid-template-columns: 1fr; /* Single column for smaller screens */
    padding-right: 10px; 
    padding-left: 10px; 
    /* justify-content: center;
    align-self: center; */
  }
}


/* .CalendarDesign {
  border: none;
  width: 100px;
  
}

.react-datepicker-wrapper {
  align-self: center !important;
}


.CalendarBodyDesign{
  border: none !important;
}
.react-datepicker__month-container {
  color: #969eac;
  font-family: 'medium';
  border: 10px;
  box-shadow: 0px 0px 8px -2px rgba(0,0,0,0.12);
  border-radius: 10px;
  
}

.react-datepicker__header {
  background-color: white !important;
  border-radius: 10px;
}

.react-datepicker__close-icon::after {
  background-color: white ;
  color: #333;
  height: 10px !important;
  width: 10px !important;
  margin-top: 2px;
  display: flex !important;
  justify-content: center !important;
  align-items: center !important;
} */
