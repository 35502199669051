.custom-dropdown {
    position: relative;
    /* z-index: 1; */
  }
  
  .dropdown-input {
    position: relative;
    display: flex;
    align-items: center;
  }
  
  .clear-icon {
    cursor: pointer;
    margin-left: 5px;
  }
  
  .dropdown-arrow {
    margin-left: 5px;
    cursor: pointer;
    transition: transform 0.3s ease;
  }

  .dropdown-options {
    position: absolute;
    top: 100%;
    /* left: 0; */
    width: 80%;
    align-self: center;
    max-height: 190px; /* Set a maximum height for the dropdown options */
  overflow-y: auto; /* Enable vertical scrolling */
    border: 1px solid #ccc;
    border-top: none;
    border-radius: 0 0 4px 4px;
    background-color: #fff;
    box-shadow: 0 2px 4px rgba(0, 0, 0, 0.1);
    z-index: 2; 
  }
  
  .dropdown-option {
    padding: 10px;
    cursor: pointer;
    &:hover {
      background-color: #f0f0f0;
    }
  }
  
  .dropdown-arrow.open {
    transform: rotate(180deg);
  }
  
  