:root {
    --primary-bg: #242526;
    --secondary-bg: #fff;
    --primary-text-color: #555;
    --secondary-text-color: #cecece;
    /* --border-radius: 8px; */
    --speed: 500ms;
  }
  
  /* body {
    margin: 0;
    font-family: -apple-system, BlinkMacSystemFont, 'Segoe UI', 'Roboto', 'Oxygen',
      'Ubuntu', 'Cantarell', 'Fira Sans', 'Droid Sans', 'Helvetica Neue',
      sans-serif;
    -webkit-font-smoothing: antialiased;
    -moz-osx-font-smoothing: grayscale;
    background-color: var(--primary-bg);
  } */
  
  /* code {
    font-family: source-code-pro, Menlo, Monaco, Consolas, 'Courier New',
      monospace;
  }
  
  ul{
    list-style: none;
  }
  
  a {
    text-decoration: none;
  } */
  
  /* .menu-trigger img{
    position: absolute;
    top: 20px;
    right: 20px;
    height: 60px;
    width: 60px;
    border-radius: 50%;
    overflow: hidden;
    cursor: pointer;
    background-color: #fff;
  } */
  
  .dropdown-menu{
    position: absolute;
    z-index: 10;
    /* top: 40px; */
    /* top: 40px;
    left: -15px; */
    background-color: #fff;
    width: 200px;
  }
  

  
  .dropdown-menu.active{
    opacity: 1;
    visibility: visible;
    transform: translateY(0);
    transition: var(--speed) ease;
  }
  
  .dropdown-menu.inactive{
    opacity: 0;
    visibility: hidden;
    transform: translateY(-20px);
    transition: var(--speed) ease;
  }
  
  
  /* h3{
    width: 100%;
    text-align: center;
    font-size: 18px;
    padding: 20px 0;
    font-weight: 500;
    font-size: 18px;
    color: var(--primary-text-color);
    line-height: 1.2rem;
  } */
  
  /* h3 span{
    font-size: 14px;
    color: var(--secondary-text-color);
    font-weight: 400;
  } */
  
  /* .dropdown-menu ul li{
    padding: 10px  0;
    border-top: 1px solid rgba(0, 0, 0, 0.05);
  }
  
  .dropdown-menu ul li:hover a{
    color: rgb(212, 33, 9);
    cursor: pointer;
  }
  
  .dropdown-menu ul li:hover img{
    opacity: 1;
    cursor: pointer;
  } */
  
  /* .dropdownItem{
    display: flex;
    margin: 10px auto;
  } */
  
  /* .dropdownItem img{
    max-width: 20px;
    margin-right: 10px;
    opacity: 0.5;
    transition: var(--speed);
  }
  
  .dropdownItem a{
    max-width: 100px;
    margin-left: 10px;
    transition: var(--speed);
  }
   */